
  
  .donut-chart {
    position: relative;
    width: 200px;
    height: 200px;
    background: conic-gradient(
      #3498db 0% 75%,
      #E9E9E9 75% 100%
    );
    border-radius: 50%;
  }
  
  .donut-inner-circle {
    position: absolute;
    width: 50%;
    height: 50%;
    background-color: #fff; /* Change color as needed */
    top: 25%;
    left: 25%;
    border-radius: 50%;
  }
  
  .donut-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: bold;
    color: #333; /* Change color as needed */
  }
  
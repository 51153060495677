@media only screen and (max-width: 460px) {
    .sideBarContainer{
        width: 100px;
        height: 100vh;
        background-color: #FFFFFF;
    }
}

@media only screen and (min-width: 760px) {
    .sideBarContainer{
        width: 6vw;
        height: 100vh;
        background-color: #FFFFFF;
        padding-bottom: 50px;
        overflow-y: scroll;
    }
    .sideBarContainer::-webkit-scrollbar{
        display: none;
    }
}